import type { ToolChatRequest } from 'app/api/generations/tool_chat/route';
import type { FormElementProps } from 'components/FormElements/types';
import type { ChatMessage } from 'features/chat/types';
import cloneDeep from 'lodash-es/cloneDeep';

import type { LocalizedToolDetailsResponse } from '@/app/api/tools/[locale]/[slug]/route';
import type { ExplicitToolConfig, FileMetadata, SafeToolInfo } from '@magicschool/business-logic/tools';
import { fitFilesInWordLimit } from '../extract-text/util';
import { storage } from '../storage';

export async function getTool(slug: string, locale = 'en-us', customizationId?: string): Promise<LocalizedToolDetailsResponse> {
  const searchParams = customizationId ? `?toolCustomizationId=${encodeURIComponent(customizationId)}` : '';
  const url = `/api/tools/${locale}/${slug}${searchParams}`;
  const response = await fetch<LocalizedToolDetailsResponse>(url);
  return await response.json();
}

export async function completeToolChat({
  threadId,
  toolDetails,
  message,
  context,
  locale,
  files,
  overrideToolConfig,
}: {
  threadId: number;
  toolDetails: SafeToolInfo;
  message: string;
  context: ChatMessage[];
  locale: string;
  files?: FileMetadata[];
  overrideToolConfig?: ExplicitToolConfig;
}) {
  const request: ToolChatRequest = {
    threadId,
    slug: toolDetails.tool.slug,
    message,
    files: files ? fitFilesInWordLimit(files) : undefined,
    locale,
    context,
    overrideToolConfig,
  };

  return await fetch('/api/generations/tool_chat', { method: 'POST', body: JSON.stringify(request) });
}

export function patchToolReuseValues(toolDetails: SafeToolInfo): SafeToolInfo {
  const fields = patchFieldReuseValues(toolDetails.fields, toolDetails.tool.slug);
  return { ...toolDetails, fields };
}

function patchFieldReuseValues(fields: FormElementProps[], toolId: string, reuseAll = false) {
  const clonedFields = cloneDeep(fields);
  const jsonString = storage.getItem(`tool-${toolId}-inputs`);
  const savedValues = jsonString ? JSON.parse(jsonString) : {};
  // This code is literally pointless because it's only used for grade level and that gets overwritten on line 62 :sob:
  for (const field of clonedFields) {
    if (field.reuseValue || reuseAll) field.initialValue = savedValues[field.name] || field.initialValue;
  }
  const gradeLevelField = clonedFields.find((field: FormElementProps) => field.name === 'gradeLevel');
  if (gradeLevelField) {
    const gradeLevel = storage.getItem(`last-grade-level-selected`);
    if (gradeLevel) {
      gradeLevelField.initialValue = gradeLevel;
    }
  }
  return clonedFields;
}

export const applyGradeLevel = (tool: SafeToolInfo, roomGradeLevel: string) => {
  const gradeLevelField = tool.fields.find((field) => field.name === 'gradeLevel');

  // check if gradeLevel exists in the fields
  if (gradeLevelField) {
    // if the value is different than the room set grade level
    if (gradeLevelField.initialValue !== roomGradeLevel) {
      gradeLevelField.initialValue = roomGradeLevel;
    }

    // we use this in FormBuilder to see if we should override showing it or not
    gradeLevelField.forceHide = true;
  }

  return tool;
};

// TODO: type inputs
export function saveToolReuseValues(inputs: any, toolId: string) {
  storage.setItem(`tool-${toolId}-inputs`, JSON.stringify(inputs));
  if (inputs.gradeLevel) {
    storage.setItem(`last-grade-level-selected`, inputs.gradeLevel);
  }
}
