import { createNewToolCustomizationServerAction } from '@/app/(teacher)/tools/actions';
import { storage } from '@/features/storage';
import type { FieldVisibility } from '@magicschool/business-logic/tools';
import type { SafeToolInfo } from '@magicschool/business-logic/tools';
import type { ToolApplicationTypeType } from '@magicschool/supabase/types';
import type { FormBuilderValues } from 'components/FormElements/FormBuilder';
import type { FormElementProps } from 'components/FormElements/types';
import type { ToolCustomization } from 'features/rooms/types';
import { type SetField, createStoreSlice } from 'features/store/zustand';
import isEqual from 'lodash-es/isEqual';
import { ImmutableList, ImmutableMap } from 'util/immutable';

type CustomizingStep = 'edit' | 'test';

export type SetToolCustomizationVisibility = (name: string, visibility: FieldVisibility) => void;

export type ToolCustomizationStore = {
  toolToCustomize: SafeToolInfo | null;
  existingToolCustomization: ToolCustomization | null;
  tempToolCustomization: ToolCustomization | null;
  templateNameModalOpen: boolean;
  templateName: string;
  invalidStateModalOpen: boolean;
  invalidFields: ImmutableList<FormElementProps>;
  toolCustomizationTemplatesMap: ImmutableMap<string, ImmutableList<ToolCustomization>>;
  cancelModalOpen: boolean;
  searchTerm: string;
  selectedCategory: string;
  selectedGradeLevel: string;
  customizingStep: CustomizingStep;
  toolApplication: ToolApplicationTypeType | null;

  startToolCustomization: (tool: SafeToolInfo, toolApplication: ToolApplicationTypeType, existingCustomization?: ToolCustomization) => void;
  setToolCustomizationVisibility: SetToolCustomizationVisibility;
  updateCustomizedTool: (values: FormBuilderValues) => void;
  saveCustomToolTitle: (title: string) => void;
  saveCustomToolDescription: (description: string) => void;
  cancelCustomizeTool: () => void;
  deleteToolTemplate: (tc: ToolCustomization) => Promise<void>;
  addToolTemplate: () => Promise<void>;
  selectToolTemplate: (selected: ToolCustomization) => void;
  openTemplateNameModal: () => void;
  resetCustomization: () => void;
  discardToolChanges: () => void;
  validateFields: (tool: SafeToolInfo, customization: ToolCustomization) => boolean;
  setField: SetField<ToolCustomizationStore>;
};

const defaultState = {
  searchTerm: '',
  selectedCategory: '',
  toolToCustomize: null,
  existingToolCustomization: null,
  tempToolCustomization: null,
  templateNameModalOpen: false,
  templateName: '',
  toolCustomizationTemplatesMap: ImmutableMap<string, ImmutableList<ToolCustomization>>(),
  invalidStateModalOpen: false,
  invalidFields: ImmutableList<FormElementProps>(),
  cancelModalOpen: false,
  selectedGradeLevel: 'pre-k',
  customizingStep: 'edit' as CustomizingStep,
  toolApplication: null,
};

export const emptyToolCustomization = (toolDetails: SafeToolInfo, defaultGradeLevel: string): ToolCustomization => {
  const emptyTool: ToolCustomization = {
    id: '',
    name: '',
    tool_slug: toolDetails.tool.slug,
    tool_uuid: toolDetails.tool.id,
    teacher_id: '',
    json_config: {
      tool_title: toolDetails.tool.title,
      tool_description: toolDetails.tool.description,
      inputs: toolDetails.fields.reduce(
        (acc, field) => ({ ...acc, [field.name]: { value: field.initialValue, visibility: 'visible' } }),
        {},
      ),
    },
    created_at: '',
    updated_at: '',
    tool_application: 'room',
    sharing_access_type: 'private',
    sharing_code: '',
  };
  if (emptyTool.json_config.inputs.gradeLevel && defaultGradeLevel) {
    emptyTool.json_config.inputs.gradeLevel.value = defaultGradeLevel;
    emptyTool.json_config.inputs.gradeLevel.visibility = 'hidden';
  }
  return emptyTool;
};

export const createToolCustomizationStoreSlice = createStoreSlice(
  'ToolCustomizationStoreData',
  defaultState,
  ({ set, get, getFull, setField }) => ({
    setField,
    startToolCustomization: (td, toolApplication, existingCustomization) => {
      getFull().ChatStoreData.clearChatStore();
      const roomGradeLevel = getFull().RoomSettingsStoreData.selectedGradeLevel;

      const initialGradeLevel = roomGradeLevel || storage.getItem(`last-grade-level-selected`) || 'pre-k';
      const tempToolCustomization = existingCustomization || emptyToolCustomization(td, initialGradeLevel);
      set({
        selectedGradeLevel: initialGradeLevel,
        toolToCustomize: td,
        tempToolCustomization,
        existingToolCustomization: tempToolCustomization,
        toolApplication,
      });
    },
    saveCustomToolTitle: (title) => {
      const { toolToCustomize, tempToolCustomization } = get();
      if (!toolToCustomize || !tempToolCustomization) return;

      const clone = structuredClone(tempToolCustomization);
      clone.json_config.tool_title = title;
      set({ tempToolCustomization: clone });
    },
    saveCustomToolDescription: (description) => {
      const { toolToCustomize, tempToolCustomization } = get();
      if (!toolToCustomize || !tempToolCustomization) return;

      const clone = structuredClone(tempToolCustomization);
      clone.json_config.tool_description = description;
      set({ tempToolCustomization: clone });
    },
    setToolCustomizationVisibility: (name, value) => {
      const { tempToolCustomization, toolToCustomize, validateFields } = get();
      if (!toolToCustomize || !tempToolCustomization) return;
      const clone = structuredClone(tempToolCustomization);
      clone.json_config.inputs[name].visibility = value;
      validateFields(toolToCustomize, clone);
      set({ tempToolCustomization: clone });
    },
    updateCustomizedTool: (values) => {
      const { tempToolCustomization, toolToCustomize, validateFields } = get();
      if (!toolToCustomize || !tempToolCustomization) return;
      const clone = structuredClone(tempToolCustomization);
      const fieldNames = Object.keys(clone.json_config.inputs);
      for (const name of fieldNames) {
        clone.json_config.inputs[name].value = values[name];
      }
      validateFields(toolToCustomize, clone);
      set({ tempToolCustomization: clone });
    },
    cancelCustomizeTool: () => {
      const { tempToolCustomization, toolToCustomize, discardToolChanges, existingToolCustomization } = get();
      if (!tempToolCustomization || !toolToCustomize) return;

      const dirty = !isEqual(existingToolCustomization?.json_config, tempToolCustomization.json_config);
      if (dirty) {
        set({ cancelModalOpen: true });
      } else {
        discardToolChanges();
      }
    },
    addToolTemplate: async () => {
      const { tempToolCustomization, templateName, toolToCustomize, validateFields } = get();
      if (!tempToolCustomization || !toolToCustomize) return;

      const fieldsValid = validateFields(toolToCustomize, tempToolCustomization);
      if (!fieldsValid) {
        set({ invalidStateModalOpen: true });
        return;
      }

      const newCustomization = await createNewToolCustomizationServerAction({
        name: templateName,
        tool_slug: tempToolCustomization.tool_slug,
        tool_uuid: tempToolCustomization.tool_uuid,
        json_config: tempToolCustomization.json_config,
        tool_application: 'room',
      });
      const data = newCustomization.data;

      set((s) => ({
        templateNameModalOpen: false,
        templateName: '',
        toolCustomizationTemplatesMap: data
          ? s.toolCustomizationTemplatesMap.update(tempToolCustomization.tool_uuid, ImmutableList(), (tcs) => tcs.push(data))
          : s.toolCustomizationTemplatesMap,
      }));
    },
    deleteToolTemplate: async (tc) => {
      const response = await fetch(`/api/tool_customizations/${tc.id}`, { method: 'DELETE' });
      if (!response.ok) return;

      set((s) => ({
        toolCustomizationTemplatesMap: s.toolCustomizationTemplatesMap.update(tc.tool_uuid, ImmutableList(), (tcs) =>
          tcs.filter((t) => t.id !== tc.id),
        ),
      }));
    },
    selectToolTemplate: (selected) => {
      const { tempToolCustomization } = get();
      if (!tempToolCustomization) return;
      set({ tempToolCustomization: { ...tempToolCustomization, json_config: { ...selected.json_config } } });
    },
    openTemplateNameModal: () => {
      const { tempToolCustomization, toolToCustomize, validateFields } = get();
      if (!tempToolCustomization || !toolToCustomize) return;

      // We don't want to allow users to save invalid templates
      const fieldsValid = validateFields(toolToCustomize, tempToolCustomization);
      const key = fieldsValid ? 'templateNameModalOpen' : 'invalidStateModalOpen';
      set({ [key]: true });
    },
    discardToolChanges: () => {
      const setField = getFull().RoomSettingsStoreData.setField;
      const { toolCustomizationTemplatesMap, selectedGradeLevel, ...resetState } = { ...defaultState };
      set(resetState);
      setField('roomToolToCustomize')(null);
    },
    resetCustomization: () => {
      const { toolToCustomize, selectedGradeLevel } = get();
      if (!toolToCustomize) return;
      set({ tempToolCustomization: emptyToolCustomization(toolToCustomize, selectedGradeLevel), invalidStateModalOpen: false });
    },
    validateFields: (tool, customization) => {
      const invalidFields = ImmutableList(tool.fields).filter((f) => {
        const overrides = customization.json_config.inputs[f.name];

        // If the field is required and hidden and has no value, it's invalid because the
        // students would not be able to see it or fill it out
        return Boolean(overrides.visibility === 'hidden' && f.required && !overrides.value);
      });

      set({ invalidFields });

      return invalidFields.isEmpty();
    },
  }),
);
