import toast from 'react-hot-toast';

import {
  type UpdateSyncPreviewStatus,
  getEdlinkIntegration,
  getSyncPreview,
  saveSyncPreview,
  syncOrg,
  updateSyncPreview,
} from '@/features/orgSync/actions';
import { AuthorizationError } from '@/features/server/actions/authErrors';
import type { EdlinkIntegration, EdlinkSyncPreviewData } from '@magicschool/business-logic/edlink-sync';
import { type Org, OrgType } from '@magicschool/supabase/types';
import type { ExtendedOrgRequest, ExtendedOrgResponse } from 'app/api/admin/orgs/[id]/route';
import type { OrgsSearchResponse } from 'app/api/admin/orgs/search/route';
import { type SetField, createStoreSlice } from 'features/store/zustand';

export const orgTypes = Object.values(OrgType);
export type Domain = {
  idx: number;
  value: string;
  set_user_org_id: boolean;
};

export type AdminOrgsStore = {
  loading: boolean;
  syncing: boolean;
  fetchingIntegration: boolean;
  fetchingPreviewSync: boolean;
  lastSynced: string | null;
  upserting: boolean;
  orgs: OrgsSearchResponse['orgs'];
  currentOrg: ExtendedOrgResponse;
  currentDomain: Domain;
  syncPreview: EdlinkSyncPreviewData | null;
  domainModalOpen: boolean;
  initialize: () => void;
  search: () => Promise<void>;
  upsert: () => Promise<ExtendedOrgResponse | void>;
  load: (orgId: string) => Promise<void>;
  reset: () => void;
  setField: SetField<AdminOrgsStore>;
  validate: () => void;
  getIntegration: (integrationId: string | null) => Promise<EdlinkIntegration | undefined>;
  getSyncPreview: () => Promise<EdlinkSyncPreviewData | null>;
  saveSyncPreview: (values: EdlinkSyncPreviewData) => void;
  updateSyncPreview: (previewId: string, status: UpdateSyncPreviewStatus) => void;
  doSync: () => Promise<void>;
  searchQuery: string;
  formErrors: Record<string, string> | null;
};

const emptyOrg = (): Org => ({
  id: '',
  name: '',
  type: orgTypes[0],
  logo_url: '',
  created_at: '',
  updated_at: '',
  auth_providers: [],
  edlink_access_token_id: '',
  edlink_integration_id: '',
  active: true,
});

export const emptyExtendedOrg = () => ({
  ...emptyOrg(),
  domains: [],
  emailCount: 0,
  enableRequireLogin: false,
  enableEdlinkSync: false,
  requireLoginDefault: false,
});

const emptyDomain = (): Domain => ({
  idx: 0,
  value: '',
  set_user_org_id: false,
});

const defaultState = {
  loading: true,
  syncing: false,
  fetchingIntegration: false,
  fetchingPreviewSync: false,
  lastSynced: '',
  upserting: false,
  orgs: [],
  currentOrg: emptyExtendedOrg(),
  currentDomain: emptyDomain(),
  domainModalOpen: false,
  searchQuery: '',
  formErrors: null,
  syncPreview: null,
};

export const createAdminOrgsStoreSlice = createStoreSlice('AdminOrgsStoreData', defaultState, ({ get, set, setField }) => ({
  setField,
  initialize: () => set({ ...defaultState, loading: false }),
  search: async () => {
    const { searchQuery } = get();
    if (searchQuery.length < 3) return;
    set({ loading: true, orgs: [] });

    const response = await fetch<OrgsSearchResponse>(`/api/admin/orgs/search?name=${encodeURIComponent(searchQuery)}`);
    const { orgs } = await response.json();

    set({ loading: false, orgs });
  },
  load: async (orgId: string) => {
    set({ loading: true, formErrors: {}, syncing: false });
    const response = await fetch<ExtendedOrgResponse>(`/api/admin/orgs/${orgId}`);
    const data = await response.json();
    set({ currentOrg: data, syncing: data.syncStatus === 'syncing', loading: false, lastSynced: data.lastSyncDate });
  },
  reset: () => {
    set({ currentOrg: emptyExtendedOrg(), formErrors: null });
  },
  upsert: async () => {
    get().validate();
    const { currentOrg: org, formErrors } = get();

    if (!formErrors) {
      set({ upserting: true });
      const response = await fetch<ExtendedOrgResponse>(`/api/admin/orgs${org.id ? `/${org.id}` : ''}`, {
        method: org.id ? 'PUT' : 'POST',
        body: JSON.stringify({ org } satisfies ExtendedOrgRequest),
        onSuccess: async ({ response }) => {
          const data = await response.json();
          toast.success(`Org successfully ${org.id ? 'updated' : 'created'}`, { duration: 4000 });
          set({ upserting: false, searchQuery: data.name, currentOrg: data });
        },
        responseErrorHandlers: {
          conflict: async ({ response }) => {
            const res = await response.json<{ error: string }>();
            const formErrors: Record<string, string> = {};
            if (res.error.indexOf('org_domain_key') >= 0) {
              formErrors.domain = 'Domain already exists.';
            } else {
              formErrors.name = 'Org name already exists.';
            }
            set({ upserting: false, formErrors: formErrors });
          },
        },
      });
      try {
        return await response.json();
      } catch (_error) {
        return;
      }
    }
  },
  validate: () => {
    const { currentOrg } = get();
    const formErrors: Record<string, string> = {};
    if (!currentOrg.name?.trim()) {
      formErrors.name = 'Name is required';
    }
    set({ formErrors: Object.keys(formErrors).length ? formErrors : null });
  },
  getIntegration: async () => {
    set({ fetchingIntegration: true });
    const { currentOrg } = get();
    const { data } = await getEdlinkIntegration(currentOrg.edlink_integration_id);
    const integration = data?.$data;
    set({ fetchingIntegration: false });
    return integration;
  },
  getSyncPreview: async () => {
    set({ fetchingPreviewSync: true });
    const { currentOrg } = get();
    const { data } = await getSyncPreview(currentOrg);
    const isAuthError = data instanceof AuthorizationError;

    if (data && !isAuthError) {
      const syncPreview = data;
      set({ fetchingPreviewSync: false, syncPreview });
      return syncPreview;
    }

    return null;
  },
  saveSyncPreview: async (values: EdlinkSyncPreviewData) => {
    const { currentOrg, syncPreview } = get();
    const result = await saveSyncPreview(currentOrg, values);
    const id = result?.data?.data?.id;

    if (id && syncPreview !== null) {
      set({ syncPreview: Object.assign(syncPreview, { id }) });
    }
  },
  updateSyncPreview: async (syncPreviewId: string, status: UpdateSyncPreviewStatus) => {
    const { currentOrg } = get();
    const orgId = currentOrg.id;
    await updateSyncPreview(orgId, syncPreviewId, status);
  },
  doSync: async () => {
    set({ syncing: true });
    const { currentOrg } = get();
    const triggerId = await syncOrg(currentOrg.id);
    if (triggerId && !(triggerId instanceof AuthorizationError)) {
      toast.success(`Sync triggered successfully (${triggerId.data}). Please check back soon.`, { duration: 8000 });
    } else {
      toast.error(`Failed to trigger sync. ${(triggerId as AuthorizationError).message}`, { duration: 8000 });
      set({ syncing: false });
    }
  },
}));
