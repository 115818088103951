import { type ToolCustomization, emptyFullRoomTool, emptyToolCustomization, generateValidToolCustomization } from 'features/rooms/types';
import { type SetField, createStoreSlice } from 'features/store/zustand';
import { formatDateAsReadableString } from 'util/date';
import { ImmutableList, ImmutableMap } from 'util/immutable';

import {
  type RoomDuplicateRequest,
  type RoomPostRequest,
  createNewRoom,
  duplicateRoomAndTools,
  updateRoom,
} from '@/app/(teacher)/rooms/actions';
import type { LiteRoom } from '@/app/api/rooms/utils';
import { emptySafeToolDetails } from '@/features/backend-api/types';
import type { ServerActionResponse } from '@/features/server/actions/types';
import { storage } from '@/features/storage';
import { clampValue } from '@/util/math';
import type { FieldVisibility } from '@magicschool/business-logic/tools';
import type { SafeToolInfo } from '@magicschool/business-logic/tools';
import { logger } from '@magicschool/logger';
import type { FullRoomTool, RoomEditResponse } from 'app/api/rooms/[id]/edit/route';
import type { RoomNewResponse } from 'app/api/rooms/new/route';
import isEqual from 'lodash-es/isEqual';
import type { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import toast from 'react-hot-toast';
import type { IntlShape } from 'react-intl';
import { revalidateRoom } from './actions';

export const steps = [
  { id: 0, titleId: 'details', headerId: 'room-settings.details-header' },
  { id: 1, titleId: 'tools', headerId: 'room-settings.tools-header' },
  { id: 2, titleId: 'customize', headerId: 'room-settings.customize-header' },
];

export type SetToolCustomizationVisibility = (name: string, visibility: FieldVisibility) => void;

export type RoomSettingsStore = {
  loading: boolean;
  launching: boolean;
  roomToolsMap: ImmutableMap<string, FullRoomTool>;
  allToolsMap: ImmutableMap<string, SafeToolInfo>;
  toolInstanceCounts: Record<string, number>;
  maxStudents: number;
  roomName: string;
  roomId: string;
  selectedGradeLevel: string;
  currentStep: number;
  isNewRoom: boolean;
  roomToolToCustomize: FullRoomTool | null;
  toolCustomizationToDelete: FullRoomTool | null;
  deleteModalOpen: boolean;
  requireLogin: boolean;
  searchTerm: string;
  selectedCategory: string;
  addTool: (tool: FullRoomTool) => boolean;
  removeTool: (tool: FullRoomTool) => void;
  removeAllTools: () => void;
  submit: (router: AppRouterInstance, intl: IntlShape) => void;
  duplicateRoom: (newRoom: RoomDuplicateRequest, intl: IntlShape) => void;
  load: (defaultText: string, roomId: string | undefined, step?: number) => void;
  startRoomToolCustomization: (tool: FullRoomTool) => void;
  deleteCustomization: () => void;
  saveCustomizedTool: () => void;
  setField: SetField<RoomSettingsStore>;
};

const defaultState = {
  launching: false,
  loading: true,
  roomToolsMap: ImmutableMap<string, FullRoomTool>(),
  allToolsMap: ImmutableMap<string, SafeToolInfo>(),
  searchTerm: '',
  selectedCategory: '',
  roomName: '',
  roomId: '',
  selectedGradeLevel: 'pre-k',
  requireLogin: false,
  currentStep: 0,
  isNewRoom: true,
  roomToolToCustomize: null,
  toolCustomizationToDelete: null,
  deleteModalOpen: false,
  maxStudents: 250,
  toolInstanceCounts: {},
};

const unsetTitleDescIfUnchanged = (toolCustomization: ToolCustomization, cleanTool: SafeToolInfo): ToolCustomization => {
  const tool_title =
    toolCustomization.json_config.tool_title === cleanTool.tool.title ? undefined : toolCustomization.json_config.tool_title;
  const tool_description =
    toolCustomization.json_config.tool_description === cleanTool.tool.description
      ? undefined
      : toolCustomization.json_config.tool_description;
  return {
    ...toolCustomization,
    json_config: {
      ...toolCustomization.json_config,
      tool_title,
      tool_description,
    },
  };
};

const patchToolCustomizationTemplates = (templates: ToolCustomization[], toolsMap: ImmutableMap<string, SafeToolInfo>) =>
  ImmutableList(templates.map((t) => generateValidToolCustomization(toolsMap?.get(t.tool_uuid, { fields: [] }).fields, t))).groupBy(
    (tc) => tc.tool_uuid,
  );

export const MAX_TOOL_INSTANCES = Number.parseInt(process.env.NEXT_PUBLIC_MAX_INSTANCES_OF_A_TOOL ?? '10');

export const createRoomSettingsStoreSlice = createStoreSlice('RoomSettingsStoreData', defaultState, ({ set, get, getFull, setField }) => ({
  setField,
  addTool: (frt) => {
    const { toolInstanceCounts } = get();
    if ((toolInstanceCounts[frt.room_tool.tool_uuid] ?? 0) >= MAX_TOOL_INSTANCES) return false;

    set((state) => {
      const updatedRoomToolsMap = state.roomToolsMap.set(frt.room_tool.id, frt);
      const toolInstanceCounts = state.toolInstanceCounts;
      toolInstanceCounts[frt.room_tool.tool_uuid] = (toolInstanceCounts[frt.room_tool.tool_uuid] || 0) + 1;
      return { roomToolsMap: updatedRoomToolsMap, toolInstanceCounts };
    });
    return true;
  },
  removeTool: (frt) => {
    set((state) => {
      const updatedRoomToolsMap = state.roomToolsMap.delete(frt.room_tool.id);
      const toolInstanceCounts = state.toolInstanceCounts;
      toolInstanceCounts[frt.room_tool.tool_uuid] = clampValue((toolInstanceCounts[frt.room_tool.tool_uuid] || 0) - 1, 0, Infinity);
      return { roomToolsMap: updatedRoomToolsMap, toolInstanceCounts };
    });
  },
  removeAllTools: () => {
    set((state) => ({
      roomToolsMap: state.roomToolsMap.clear(),
      toolInstanceCounts: {}, // Reset toolInstanceCounts to an empty object
    }));
  },
  startRoomToolCustomization: (frt) => {
    const { allToolsMap } = get();
    const toolDetails = allToolsMap.get(frt.room_tool.tool_uuid);
    if (!toolDetails) return;
    getFull().ToolCustomizationStoreData.startToolCustomization(toolDetails, 'room', frt.tool_customization || undefined);
    set({ roomToolToCustomize: frt });
  },
  saveCustomizedTool: () => {
    const {
      tempToolCustomization,
      toolToCustomize,
      validateFields,
      selectedGradeLevel,
      setField: setCustomToolField,
    } = getFull().ToolCustomizationStoreData;
    const { roomToolsMap, allToolsMap, roomToolToCustomize } = get();
    const toolDetails = allToolsMap.get(toolToCustomize?.tool.id || '');
    if (!tempToolCustomization || !toolToCustomize || !toolDetails || !roomToolToCustomize) return;

    const fieldsValid = validateFields(toolDetails, tempToolCustomization);
    if (!fieldsValid) {
      setCustomToolField('invalidStateModalOpen')(true);
      return;
    }

    const existingCustomization = roomToolToCustomize.tool_customization;
    const defaultCustomizations = emptyToolCustomization(toolDetails, selectedGradeLevel);
    const fieldsChangedFromDefault = !isEqual(defaultCustomizations.json_config, tempToolCustomization.json_config);
    const fieldsDirty = !existingCustomization ? false : !isEqual(existingCustomization.json_config, tempToolCustomization.json_config);

    let tempRoomToolsMap = roomToolsMap;
    if (!fieldsChangedFromDefault) {
      // If the fields are the same as the default, remove the customization
      tempRoomToolsMap = roomToolsMap.update(roomToolToCustomize.room_tool.id, (frt) => {
        return { ...(frt ?? emptyFullRoomTool()), tool_customization: null };
      });
    } else if (fieldsChangedFromDefault || fieldsDirty) {
      // if the fields were changed, update the customization
      tempRoomToolsMap = roomToolsMap.update(roomToolToCustomize.room_tool.id, (frt) => {
        return {
          ...(frt ?? emptyFullRoomTool()),
          tool_customization: structuredClone(unsetTitleDescIfUnchanged(tempToolCustomization, toolDetails)),
        };
      });
    }

    set({ roomToolsMap: tempRoomToolsMap });
    setCustomToolField('toolToCustomize')(null);
  },
  deleteCustomization: () => {
    const { toolCustomizationToDelete, roomToolsMap } = get();
    if (!toolCustomizationToDelete) return;

    set({
      deleteModalOpen: false,
      roomToolsMap: roomToolsMap.update(toolCustomizationToDelete.room_tool.id, (frt) => ({
        ...(frt ?? emptyFullRoomTool()),
        tool_customization: null,
      })),
    });
  },
  submit: async (router, intl) => {
    const { roomName, maxStudents, roomId, roomToolsMap, selectedGradeLevel, requireLogin, allToolsMap } = get();

    set({ launching: true });
    const roomData: RoomPostRequest = {
      name: roomName,
      gradeLevel: selectedGradeLevel,
      maxStudents,
      requireLogin,
      tools: Array.from(roomToolsMap.values()).map((t) => {
        const toolDetails = allToolsMap.get(t.room_tool.tool_uuid, emptySafeToolDetails());
        return {
          tool_slug: toolDetails.tool.slug,
          tool_uuid: toolDetails.tool.id,
          tool_customization: t.tool_customization,
          room_tool_id: t.room_tool.id,
        };
      }),
    };

    let res: ServerActionResponse<LiteRoom>;
    if (roomId) {
      res = await updateRoom({ ...roomData, id: roomId });
      if (res.data) {
        await revalidateRoom(roomId);
        router.push(`/rooms/${roomId}`);
      }
    } else {
      res = await createNewRoom(roomData);
      if (res.data) {
        const newRoom = res.data.room;
        router.push(`/rooms/${newRoom.id}?joinInfoModalOpen=true`);
      }
    }
    storage.setItem(`last-grade-level-selected`, selectedGradeLevel);
    if (res.error) {
      logger.error(res.error.message, { error: res.error });
      set({ launching: false });
      router.push(`/rooms`);
      toast.error(intl.formatMessage({ id: 'toast.error.generic' }), { duration: 4000 });
    }
  },
  duplicateRoom: async (newRoom, intl) => {
    set({ launching: true });
    // Append internationalized [Copy] to the room name
    newRoom.name = `[${intl.formatMessage({ id: 'copy' })}] ${newRoom.name}`;
    const res = await duplicateRoomAndTools(newRoom);
    if (res.data) {
      getFull().RoomListStoreData.addRoom(res.data);
      toast.success(intl.formatMessage({ id: 'duplicate-room.success' }), { duration: 4000 });
    } else if (res.error) {
      logger.error(res.error.message, { error: res.error });
      set({ launching: false });
      toast.error(intl.formatMessage({ id: 'duplicate-room.error' }), { duration: 4000 });
    }
  },

  load: async (defaultText, roomId, step) => {
    set({ ...defaultState, currentStep: step });

    if (roomId) {
      const response = await fetch(`/api/rooms/${roomId}/edit`);
      const {
        room,
        allToolsMap: apiAllToolsMap,
        fullRoomToolsMap,
        toolInstanceCounts,
        toolCustomizationTemplates,
      }: RoomEditResponse = await response.json();
      const allToolsMap = ImmutableMap(apiAllToolsMap);
      const roomToolsMap = ImmutableMap(fullRoomToolsMap).map((frt) => {
        const tool = allToolsMap.get(frt.room_tool.tool_uuid);
        return {
          ...frt,
          tool_customization: frt.tool_customization && tool ? generateValidToolCustomization(tool.fields, frt.tool_customization) : null,
        };
      });

      set({
        roomId,
        roomToolsMap,
        allToolsMap,
        loading: false,
        isNewRoom: false,
        selectedGradeLevel: room.grade_level,
        roomName: room.name,
        maxStudents: room.max_students,
        requireLogin: room.require_login,
        toolInstanceCounts,
      });
      getFull().ToolCustomizationStoreData.setField('selectedGradeLevel')(room.grade_level);
      getFull().ToolCustomizationStoreData.setField('toolCustomizationTemplatesMap')(
        patchToolCustomizationTemplates(toolCustomizationTemplates, ImmutableMap(allToolsMap)),
      );
    } else {
      const response = await fetch<RoomNewResponse>(`/api/rooms/new`);
      const { allToolsMap, requireLogin, toolCustomizationTemplates } = await response.json();
      set({
        allToolsMap: ImmutableMap(allToolsMap),
        loading: false,
        isNewRoom: true,
        selectedGradeLevel: storage.getItem(`last-grade-level-selected`) || 'pre-k',
        roomName: `${defaultText} - ${formatDateAsReadableString(new Date())}`,
        requireLogin,
        toolInstanceCounts: {},
      });
      getFull().ToolCustomizationStoreData.setField('selectedGradeLevel')(storage.getItem(`last-grade-level-selected`) || 'pre-k');
      getFull().ToolCustomizationStoreData.setField('toolCustomizationTemplatesMap')(
        patchToolCustomizationTemplates(toolCustomizationTemplates, ImmutableMap(allToolsMap)),
      );
    }
  },
}));
