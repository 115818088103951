import { createNewToolCustomizationServerAction } from '@/app/(teacher)/tools/actions';
import type { DashboardTool } from '@magicschool/business-logic/tools';
import type { Category, ReturnTypeGetUserRecommendations } from '@magicschool/supabase/types';
import { type SetField, createStoreSlice } from 'features/store/zustand';
import type { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import toast from 'react-hot-toast';
import type { IntlShape } from 'react-intl';

export type ToolsStore = ToolsData & ToolsActions;
export type ToolsSortType = 'popular' | 'newest' | 'az' | 'za';

export type ToolsData = {
  selectedCategory: string;
  searchTerm: string;
  headerHeight: number;
  tools: DashboardTool[];
  recommendedTools: ReturnTypeGetUserRecommendations;
  categories: Category[];
  showStudent: boolean;
  searchResults: string[];
  sortType: ToolsSortType;
  loadingNewCustomization: boolean;

  saveToolCustomization: (intl: IntlShape, router: AppRouterInstance) => void;
};

const defaultState = {
  selectedCategory: '',
  searchTerm: '',
  headerHeight: 0,
  tools: [],
  recommendedTools: [],
  categories: [],
  showStudent: false,
  searchResults: [],
  sortType: 'popular' as const,
  loadingNewCustomization: false,
};

export type ToolsActions = {
  setField: SetField<ToolsStore>;
};

export const createToolsPageStoreSlice = createStoreSlice(
  'ToolsStoreData',
  structuredClone(defaultState),
  ({ setField, set, get, getFull }) => ({
    setField,
    saveToolCustomization: async (intl, router) => {
      set({ loadingNewCustomization: true });
      const {
        tempToolCustomization,
        toolToCustomize,
        validateFields,
        discardToolChanges,
        setField: setCustomToolField,
      } = getFull().ToolCustomizationStoreData;
      if (!tempToolCustomization || !toolToCustomize) return;

      const fieldsValid = validateFields(toolToCustomize, tempToolCustomization);
      if (!fieldsValid) {
        setCustomToolField('invalidStateModalOpen')(true);
        return;
      }

      const newCustomization = await createNewToolCustomizationServerAction({
        tool_slug: tempToolCustomization.tool_slug,
        tool_uuid: tempToolCustomization.tool_uuid,
        json_config: tempToolCustomization.json_config,
        tool_application: 'individual',
      });

      if (newCustomization.error) {
        toast.error(intl.formatMessage({ id: 'new-custom-tool.error' }));
        throw new Error(newCustomization.error.message);
      } else if (newCustomization.data) {
        const customization = newCustomization.data;
        const dashboardTool: DashboardTool = {
          title: customization.json_config?.tool_title || toolToCustomize.tool.title,
          description: customization.json_config?.tool_description || toolToCustomize.tool.description,
          isNew: false,
          isHot: false,
          priority: toolToCustomize.tool.priority,
          categoryIds: toolToCustomize.categories.map((c) => c.id),
          toolSlug: toolToCustomize.tool.slug,
          toolId: toolToCustomize.tool.id,
          toolCustomizationId: customization.id,
          roles: toolToCustomize.tool.roles,
          updatedAt: customization.updated_at,
          icon: toolToCustomize.tool.icon,
        };
        set({
          tools: [dashboardTool, ...get().tools],
        });
        toast.success(intl.formatMessage({ id: 'new-custom-tool.success' }));
      }
      set({ loadingNewCustomization: true });
      discardToolChanges();
      router.replace('/tools');
    },
  }),
);
